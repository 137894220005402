import React, { Suspense, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from "react-helmet";
import axios from "axios";
import Layout from "../components/Layout";
import Particle from "../components/Particle";
import Socialicons from "../components/Socialicons";
import Spinner from "../components/Spinner";

const ClientPreview = ({ lightMode }) => {
  const { clientName } = useParams();
  const [information, setInformation] = useState("");
  const [ClientComponent, setClientComponent] = useState(null);
  const [loadingClient, setLoadingClient] = useState(true);

  useEffect(() => {
    axios.get("/api/information").then((response) => {
      setInformation(response.data);
    });

    // Dynamic import of client component
    const importClient = async () => {
      try {
        const module = await import(`./clients/${clientName}/index.js`);
        setClientComponent(() => module.default);
      } catch (error) {
        console.error(`Failed to load client component: ${error}`);
        setClientComponent(null);
      } finally {
        setLoadingClient(false);
      }
    };

    importClient();
  }, [clientName]);

  return (
    <Layout>
      <Helmet>
        <title>{`${clientName} Preview - Nathan Hensher`}</title>
        <meta
          name="description"
          content={`Preview for ${clientName} - Nathan Hensher's Portfolio`}
        />
      </Helmet>
      <Suspense fallback={<Spinner />}>
        <div className="mi-home-area mi-padding-section">
          <Particle lightMode={lightMode} />
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-10 col-12">
                <div className="mi-home-content">
                  <h1>
                    Preview for <span className="color-theme">{clientName}</span>
                  </h1>
                  {loadingClient ? (
                    <Spinner />
                  ) : ClientComponent ? (
                    <div className="w-full border border-gray-300 rounded-md">
                      <ClientComponent />
                    </div>
                  ) : (
                    <p>Sorry, we couldn't find a preview for client: {clientName}</p>
                  )}
                  <Socialicons bordered />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Suspense>
    </Layout>
  );
};

export default ClientPreview;
