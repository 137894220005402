import React, { useMemo } from 'react';
import useProgressiveImage from '@ohs/use-progressive-image';

const Image = ({ lqip, src, avif }) => {
  // It's recommended to memoize `sources` array, otherwise it will be deep
  // compared with previous value on each rerender in `useProgressiveImage`
  const sources = useMemo(() => [{
    srcSet: avif,
    type: 'image/avif'
  }], [avif]);

  const [loading] = useProgressiveImage({ img: src, sources });

  return (
    <picture>
      {!loading && <source {...sources[0]} />}
      <img src={loading ? lqip : src} />
    </picture>
  );
};

export default Image;
